/**
 * 客户欠款明细
 */
 import * as API from '@/api/index'

 export default {
    // 顾客欠款列表
    arrearsDetail: params => {
        return API.POST('api/customerStatement/arrearsDetail', params)
     },
    // 明细导出
    arrearsDetailExcel: params => {
        return API.exportExcel('api/customerStatement/arrearsDetailExcel', params)
     },

 }